<template>
  <div class="report-wrap">
    <form-search :form="searchForm" @search="search" />
    <el-table
      :data="tableData"
      border
      stripe
      align="center"
      style="width: 100%"
    >
      <el-table-column type="index" width="50" align="center" label="序号" />
      <el-table-column prop="projectName" align="center" label="企业名称">
        <template #default="scope">
          <span
            class="clickable"
            @click="
              router.push({
                path: `/maintenance/reports`,
                query: {
                  checkProjectId: scope.row.id,
                  projectName: scope.row.projectName
                }
              })
            "
            >{{ scope.row.projectName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        align="center"
        label="创建时间"
        width="220"
      />
      <el-table-column
        prop="wholeTime"
        align="center"
        label="最新推送时间"
        width="220"
      />
    </el-table>
    <el-pagination
      background
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[15, 30, 50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    />
  </div>
</template>

<script setup>
import { ref, watch, onActivated } from 'vue'
import FormSearch from '@/components/FormSearch'
import { searchForm } from './data'
import { findProjectList } from '@/api/maintenance-reports'
import { tableSearchPagination } from '@/utils/table'

import { useRouter } from 'vue-router'
const router = useRouter()

const {
  pageIndex,
  pageSize,
  handleSizeChange,
  handleCurrentChange,
  total,
  search,
  searchPaginationParams
} = tableSearchPagination()

watch(searchPaginationParams, () => {
  loadTableData()
})

/**
 * 表格数据
 */
const tableData = ref([])
const loadTableData = async () => {
  const result = await findProjectList({
    ...searchPaginationParams.value,
    findCheck: 2 // 1检测2维保
  })
  tableData.value = result.listObj
  total.value = result.totalCount
}

onActivated(() => {
  loadTableData()
})
</script>

<style lang="scss" scoped>
.el-table :deep(thead th) {
  background-color: #eff4fa;
}
.el-pagination {
  margin-top: 20px;
}
</style>
