<template>
  <el-dialog
    v-model="dialogFormVisible"
    title="上传结果反馈"
    @opened="handleOpened"
  >
    <el-form ref="formFef" :model="form" :rules="rules">
      <el-form-item label="上传结果：" prop="result">
        <el-checkbox-group v-model="form.result" @change="setResult">
          <el-checkbox :label="3">推送成功</el-checkbox>
          <el-checkbox :label="4">推送失败</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        v-if="form.result.toString() === '4'"
        label="失败原因："
        prop="reason"
      >
        <el-input
          v-model="form.reason"
          :rows="8"
          type="textarea"
          placeholder="请描述失败原因（例：项目负责人账号或密码错误）"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="validate">确定</el-button>
        <el-button @click="dialogFormVisible = false">取消</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineExpose, defineProps, defineEmits } from 'vue'
import {
  findContract,
  findConference,
  modifyPlanWhole
} from '@/api/maintenance-reports'
import { ElMessage } from 'element-plus'

const props = defineProps({
  report: {
    type: Object,
    required: true,
    default: () => ({})
  }
})

const dialogFormVisible = ref(false)
defineExpose({
  dialogFormVisible
})

const form = ref({
  result: [],
  reason: ''
})

const rules = {
  result: [
    {
      required: true,
      message: '请选择上传结果',
      trigger: 'change'
    }
  ],
  reason: [
    {
      required: true,
      message: '请描述失败原因',
      trigger: 'change'
    }
  ]
}

const setResult = (value) => {
  form.value.result = [value[value.length - 1]]
}

const promise = ref(null)
// watch(
//   () => props.report,
//   (value) => {
//     contract.value = {}
//     console.log(value)
//     promise.value = Promise.all([
//       findReportContract(value),
//       findReportConference(value)
//     ])
//     console.log(promise.value)
//   }
// )

const handleOpened = () => {
  reset()
  form.value.result = [props.report.wholeStatus]
  form.value.reason = props.report.wholeStatusInfo
  promise.value = Promise.all([
    findReportContract(props.report),
    findReportConference(props.report)
  ])
}

const contract = ref({})
const findReportContract = async (item) => {
  const params = {
    planId: item.id,
    projectId: item.projectId,
    checkProjectId: item.checkProjectId,
    wholeStatus: item.wholeStatus
  }
  const { obj } = await findContract(params)
  contract.value = obj
}

const conference = ref({})
const findReportConference = async (item) => {
  const params = {
    planId: item.id,
    projectId: item.projectId,
    checkProjectId: item.checkProjectId
  }
  const { obj } = await findConference(params)
  conference.value = obj
}

const formFef = ref(null)
const validate = () => {
  formFef.value.validate((valid) => {
    if (valid) {
      submit()
    } else {
      console.log('error submit!!')
      return false
    }
  })
}

const emits = defineEmits(['success'])
let submitting = false
const submit = async () => {
  if (submitting) {
    return
  }
  submitting = true
  await promise.value
  const wholeStatus = form.value.result[0]
  let params = {
    id: props.report.planWholeId,
    wholeStatus,
    wholeStatusInfo: form.value.reason
  }
  if (wholeStatus === 3) {
    // 成功
    if (!conference.value || !conference.value.id) {
      ElMessage.error('没有会议数据')
      return
    }
    if (!contract.value || !contract.value.id) {
      ElMessage.error('没有合同数据')
      return
    }
    params = {
      ...props.report,
      ...contract.value,
      conferenceId: conference.value.id,
      planId: props.report.id,
      id: props.report.planWholeId,
      wholeStatus,
      wholeStatusInfo: form.value.reason
    }
  }

  try {
    await modifyPlanWhole(params)
    ElMessage.success('保存成功')
    emits('success')
    dialogFormVisible.value = false
  } finally {
    submitting = false
  }
}

const reset = () => {
  contract.value = {}
}
</script>

<style lang="scss" scoped>
</style>
