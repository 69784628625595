<template>
  <el-dialog
    v-model="dialogVisible"
    title="合同登记详情"
    @open="reset"
    @opened="handleOpened"
  >
    <div v-if="empty" class="empty-box">没有合同数据</div>
    <template v-else>
      <h3>合同基本信息</h3>
      <el-row class="contract-info">
        <el-col v-for="item in contractInfo" :key="item.label" :span="12"
          >{{ item.label }}：<el-button
            v-if="item.type === 'download'  && item.value"
            type="text"
            @click="downloadFile(item.value, item.label)"
            >下载</el-button
          >
          <template v-else
            >{{ item.valueUnit }}

            <!-- <el-button class="copy-btn" size="mini" @click="copy(item.value)"
              >复制</el-button
            > -->
            </template
          ></el-col
        >
      </el-row>
      <h3>合同内容</h3>
      <el-row class="contract-content">
        <el-col v-for="item in contractContent" :key="item.label" :span="12"
          >{{ item.label }}：
          {{ item.valueUnit }}

          <!-- <el-button class="copy-btn" size="mini" @click="copy(item.value)"
            >复制</el-button
          > -->
          </el-col
        >
      </el-row>

      <h3>项目人员</h3>
      <el-row>
        <el-col v-for="item in contractUser" :key="item.label" :span="12"
          >{{ item.label }}：
          {{ item.valueUnit }}

          <!-- <el-button class="copy-btn" size="mini" @click="copy(item.value)"
            >复制</el-button
          > -->
          </el-col
        >
      </el-row>

      <el-form>
        <el-form-item label="维保内容">
          <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="item in checkList" :key="item" :label="item" disabled="true" />
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <h3>其它事项</h3>
      <div>{{ otherBusiness }}</div>
      <el-row>
        <el-col :span="24"
          >项目概况：
          {{ projectOverview }}

          <!-- <el-button class="copy-btn" size="mini" @click="copy(item.value)"
            >复制</el-button
          > -->
          </el-col
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineExpose } from 'vue'
// import { copy } from '@/utils/copy'
import { downloadFile, dataConversion } from '@/utils/common'
import { findContract } from '@/api/maintenance-reports'
import {
  contractInfoModel,
  contractContentModel,
  contractUserModel
} from '../data'

const dialogVisible = ref(false)
defineExpose({
  dialogVisible
})
const props = defineProps({
  report: {
    type: Object,
    required: true,
    default: () => ({})
  }
})

// watch(
//   () => props.report,
//   (value) => {
//     findReportContract(value)
//   }
// )

const handleOpened = () => {
  findReportContract(props.report)
}

const contractInfo = ref([])
const contractContent = ref([])
const contractUser = ref([])
const checkList = ref([])
const otherBusiness = ref('')
const empty = ref(false)
const projectOverview = ref('')
const findReportContract = async (item) => {
  const params = {
    planId: item.id,
    projectId: item.projectId,
    checkProjectId: item.checkProjectId,
    wholeStatus: item.wholeStatus
  }
  const { obj } = await findContract(params)
  if (!obj) {
    empty.value = true
    return
  } else {
    empty.value = false
  }
  const contract = obj || {}
  contractInfo.value = contractInfoModel.map(dataConversion(contract))
  contractContent.value = contractContentModel.map(dataConversion(contract))
  contractUser.value = contractUserModel.map(dataConversion(contract))
  checkList.value = contract.sysTypeNames
    .split(',')
    .map((item) => item.replace(';', ''))
  otherBusiness.value = contract.otherBusiness || ''
  projectOverview.value = contract.projectSurvey || ''
  console.log('findReportContract')
}

const reset = () => {
  contractInfo.value = []
  contractContent.value = []
  contractUser.value = []
  checkList.value = []
  otherBusiness.value = ''
}
</script>

<style lang="scss" scoped>
.el-col,
h3 {
  line-height: 36px;
}
h3 {
  font-size: 15px;
}
.copy-btn {
  margin-left: 5px;
  padding: 0px 5px;
}
.el-form-item {
  margin-bottom: 0px;
  :deep(.el-form-item__label) {
    font-weight: 600;
    font-size: 15px;
  }
}
.contract-info {
  min-height: 76px;
}
.contract-content {
  min-height: 144px;
}
.empty-box {
  min-height: 447px;
  text-align: center;
  line-height: 447px;
}

:deep(.el-checkbox__input.is-disabled+span.el-checkbox__label) {
  color: var(--el-checkbox-checked-text-color);
  cursor: auto;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
</style>
